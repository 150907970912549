


















import { Component, Prop, Vue } from 'vue-property-decorator';
import TheHeartIcon from '@/components/icons/TheHeartIcon.vue';

@Component({
  components: { TheHeartIcon },
})
export default class AddToFavoriteAction extends Vue {
  @Prop({ default: false, type: Boolean }) isFavorite: boolean;
}
